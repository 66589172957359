import Vue from 'vue';
import TouchstoneAssets from '@touchstone/touchstone-assets';
import App from './App.vue';
import router from './router';
import Common from './components';

import './assets/css/app.css';

Vue.config.productionTip = false;
Vue.use(TouchstoneAssets);
Vue.use(Common);

new Vue({
    router,
    render: h => h(App)
}).$mount('#app');
