import axios from 'axios';

export default class {
    constructor() {
        this.http = axios.create({
            baseURL: process.env.VUE_APP_BASE_API_URL
        });
    }

    searchApi() {
        return {
            searchBuilding: (data) => {
                const url = '/api/cabuildingid/lookup';
                return this.http.post(url, data);
            },
            searchBuildingById: (data) => {
                const url = '/api/buildingid/lookup-id/california';
                return this.http.post(url, data);
            },
            getCities: () => {
                const url = '/api/cabuildingid/regions';
                return this.http.get(url);
            }
        };
    };
};
