<template>
    <div class="min-h-screen w-full bg-stone-100 flex flex-col">
        <header class="block w-full bg-white shadow px-4 flex items-center h-16">
            <router-link to="/">
                <img class="h-10" src="../assets/images/TIQ_PRIMARY_hor_blk.png" alt="Touchstone IQ Logo">
            </router-link>
            <!--<anchor-tag class="ml-auto" href="mailto:info@touchstoneiq.com?subject=Touchstone IQ - Building Search">
                <span class="sr-only">Help: Contact Us</span>
                <help-icon class="h-5 w-5" />
            </anchor-tag>-->
        </header>
        <main class="flex-1 pt-6 px-4">
            <slot />
        </main>
        <footer-bar />
    </div>
</template>
<script>
import FooterBar from './FooterBar';
export default {
    components: {
        FooterBar
    }
};
</script>
