<template>
    <section>
        <div class="w-full max-w-2xl mx-auto" v-if="results && results.length == 0">
            <div class="px-6">
                <h1 class="text-3xl font-bold test-gray-700">Need to find your building?</h1>
                <p class="text-gray-700 text-lg font-semibold mt-2">
                    California’s benchmarking requires a Benchmarking Reference Number (BRN) or Local Jurisdiction ID as part of the report submission process. Seamlessly identify yours with our helpful lookup tool.
                </p>
            </div>
            <div class="mt-4">
                <card-container spacing="lg" class="w-full">
                    <tabs-menu class="mb-4" :tabs="tabs" :selected-tab="selectedTab" @change="handleChangeTab" />
                    <form id="panel_1" @submit.prevent="search" v-show="selectedTab === 0">
                        <view-errors :error="error" />
                        <div>
                            <div class="grid sm:grid-cols-2 sm:gap-x-4">
                                <form-group class="sm:mb-0">
                                    <form-label for="street" is-required>Street</form-label>
                                    <text-input id="street" class="block w-full" v-model="address.street" />
                                </form-group>
                                <form-group class="!mb-0">
                                    <form-label for="city">City</form-label>
                                    <select-input id="city" class="block w-full" v-model="address.city">
                                        <option value="all">All</option>
                                        <option v-for="item in cities" :key="item" :value="item">
                                            {{ item | capitalize_words_format }}
                                        </option>
                                    </select-input>
                                </form-group>
                            </div>
                            <div class="mt-4">
                                <loading-button type="submit" :loading="loading" :disabled="!address.street">
                                    <search-icon class="h-4 w-4 mr-2" />
                                    Search
                                </loading-button>
                            </div>
                        </div>
                    </form>
                    <form id="panel_2" @submit.prevent="searchById" v-show="selectedTab === 1">
                        <view-errors :error="error" />
                        <div>
                            <form-group>
                                <form-label for="building_id" is-requred>BRN / Local Jurisdiction ID</form-label>
                                <text-input id="building_id" class="block w-full" v-model="building.building_id" />
                            </form-group>
                            <div class="mt-4">
                                <loading-button type="submit" :loading="loading" :disabled="!building.building_id">
                                    <search-icon class="h-4 w-4 mr-2" />
                                    Search
                                </loading-button>
                            </div>
                        </div>
                    </form>
                </card-container>
            </div>
        </div>
        <div v-else>
            <div class="flex flex-col lg:flex-row lg:items-center">
                <div class="flex-1 mr-2">
                    <h1 class="text-2xl font-bold test-gray-700">Buildings matching search criteria</h1>
                    <p class="text-gray-700 font-semibold mt-2">
                        Don't see your building listed? Please adjust your search to help us find your building.
                    </p>
                </div>
                <div class="mt-4 lg:mt-0">
                    <base-button @click="handleClearResults">
                        <chevron-left-icon class="h-5 w-5 mr-2" />
                        New Search
                    </base-button>
                </div>
            </div>
            <card-container spacing="lg" class="w-full mt-4 overflow-hidden">
                <div class="-mx-6 -my-4">
                    <base-table class="report-table mb-2" :data="results" :columns="columns" :loading="loading" :current-page="paging.currentPage" :per-page="paging.perPage" paginated>
                        <template v-slot="{ row }">
                            <td>{{ row.custom_building_id }}</td>
                            <td class="capitalize">{{ row.usetype | empty_string_format }}</td>
                            <td>{{ row.gross_floor_area | number_format }}</td>
                            <td>{{ row.gfa_aggregate | number_format }}</td>
                            <td>{{ row.status | capitalize_words_format }}</td>
                            <td>{{ row.previous_year_status | capitalize_words_format }}</td>
                            <td>{{ row.street | capitalize_words_format }}</td>
                            <td>{{ row.city | capitalize_words_format }}</td>
                            <td><span class="uppercase">{{ row.state }}</span></td>
                            <td>{{ row.zipcode }}</td>
                        </template>
                    </base-table>
                </div>
            </card-container>
        </div>
    </section>
</template>
<script>
import { SearchIcon, ChevronLeftIcon, ViewErrorsMixin } from '@touchstone/touchstone-assets';
import ApiService from '@/services/apiService';
export default {
    name: 'SearchBuilding',
    mixins: [ViewErrorsMixin],
    components: {
        ChevronLeftIcon,
        SearchIcon,
    },
    data() {
        const year = (new Date()).getFullYear();
        return {
            address: {
                street: '',
                city: 'los angeles',
            },
            building: {
                building_id: ''
            },
            columns: [
                { label: 'BRN / Local Jurisdiction ID', field: 'custom_building_id' },
                { label: 'Use Type', field: 'usetype' },
                { label: 'Assessor GFA (sq-ft)', field: 'gross_floor_area' },
                { label: 'Aggregate GFA (sq-ft)', field: 'aggregate_gfa' },
                { label: `${year} status`, field: 'current_year_building_status' },
                { label: `${year - 1} status`, field: 'previous_year_building_status' },
                { label: 'Street', field: 'street' },
                { label: 'City', field: 'city' },
                { label: 'State', field: 'state' },
                { label: 'Zip Code', field: 'postal_code' }
            ],
            tabs: [
                'Search by Address', 'Search by BRN / Local Jurisdiction ID'
            ],
            selectedTab: 0,
            paging: {
                currentPage: 1,
                perPage: 10
            },
            loading: false,
            results: [],
            cities: []
        };
    },
    methods: {
        async search() {
            try {
                this.loading = true;
                this.clearError();
                const { data } = await this.api.searchBuilding(this.address);
                if (data && data.length === 0) {
                    this.$touchstone.notify({
                        color: 'black',
                        message: 'No matching results were found.',
                        position: 'top-right'
                    });
                }
                this.results = data;
            } catch (e) {
                this.setError(e);
            }
            this.loading = false;
        },
        async searchById() {
            try {
                this.loading = true;
                this.clearError();
                const { data } = await this.api.searchBuildingById(this.building);
                if (data && data.length === 0) {
                    this.$touchstone.notify({
                        color: 'black',
                        message: 'No matching results were found.',
                        position: 'top-right'
                    });
                }
                this.results = data;
            } catch (e) {
                this.setError(e);
            }
            this.loading = false;
        },
        async getCities() {
            const { data } = await this.api.getCities();
            this.cities = data;
        },
        handleClearResults() {
            this.results = [];
        },
        handleChangeTab(index) {
            this.selectedTab = index;
        },
    },
    created() {
        const srv = new ApiService();
        this.api = srv.searchApi();
        this.getCities();
    }
};
</script>
